import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from '../templates/blog-post';
import { graphql } from 'gatsby';
import CodeExample from '../components/CodeExample';
export const _frontmatter = {
  "title": "A Formula for Getting Unstuck",
  "date": "2017-11-06",
  "promo": "grids",
  "description": "Three steps for getting from frustrated to inspired",
  "color": "#e2fd4f"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = BlogPost;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Everybody run into snags. You probably know the feeling well - you're trying your hardest to figure something out, but solid ideas just aren't coming. You take a shot at one `}<em parentName="p">{`meh`}</em>{` solution after another as the frustration rises. `}<strong parentName="p">{`You're stuck`}</strong>{`.`}</p>
    <p>{`Something they never tell you is that even the most experienced professionals get stumped on a regular basis. In fact I think "what are you currently stuck on?" would make a fantastic interview question. So when you get stuck, don't get discouraged. Instead see it as a sign that you're challenging yourself and progressing in your skills. But what should you do in the moment when you're stuck? `}<strong parentName="p">{`How do you get unstuck`}</strong>{`?`}</p>
    <h2>{`Paul Simon Knows Your Feels`}</h2>
    <p>{`You know the song `}<a parentName="p" {...{
        "href": "https://youtu.be/a5_QV97eYqM?t=28s"
      }}>{`Cecilia`}</a>{` by Simon and Garfunkel? Paul Simon had this catchy tune his in mind but was having a rough time coming up with lyrics for it. He was stuck. I can imagine him in a striped shirt and leather jacket, plucking notes on his guitar, experimenting with a bunch of `}<em parentName="p">{`meh`}</em>{` phrases. Eventually something `}<em parentName="p">{`clicked`}</em>{` and he used this lack of inspiration as the inspiration itself. He wrote `}<em parentName="p">{`Cecilia`}</em>{` to lament about that feeling of helplessness when your source of inspiration is fleeting. His lyrics make a lot of sense when you realize who Cecilia is - the `}<em parentName="p">{`patron saint of music`}</em>{` according to the Catholic tradition.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Cecilia, you're breaking my heart`}<br /><br parentName="p"></br>{`
`}{`You're shaking my confidence daily`}<br /><br parentName="p"></br>{`
`}{`Oh Cecilia, I'm down on my knees`}<br /><br parentName="p"></br>{`
`}{`I'm begging you please to come home`}</p>
    </blockquote>
    <p><img parentName="p" {...{
        "src": "/img/cecilia2.jpg",
        "alt": "cecilia painting"
      }}></img></p>
    <p className="note">St Cecilia, by painter Nicolas Poussin</p>
    <p>{`Paul got unstuck by writing about the problem he was facing. If a celebrated songwriter who composed around `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/List_of_songs_written_by_Paul_Simon"
      }}>{`two hundred songs`}</a>{` can get stuck, so can you and I.`}</p>
    <h2>{`I Know Your Feels`}</h2>
    <p>{`The other day I was trying to solve what I felt like should have been a fairly easy problem. I wanted to create a sweet laptop sticker layout using hexagons and characters from my UI Mastery Games `}<a parentName="p" {...{
        "href": "//flexboxzombies.com"
      }}>{`Flexbox Zombies`}</a>{` and `}<a parentName="p" {...{
        "href": "//gridcritters.com"
      }}>{`Grid Critters`}</a>{`.`}</p>
    <p>{`I started sketching some ideas on my iPad and was feeling pretty good about it.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/hex-start.jpg",
        "alt": "sketching ideas out"
      }}></img></p>
    <p>{`I wanted to give the main characters like Meg some bigger stickers, yet still have all the hex shapes fit together nicely. One idea I tried was lengthening Meg's hexagon shape like this:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/tall-hex.jpg",
        "alt": "tall hexagon shapes"
      }}></img></p>
    <p>{`But that left an awkward little gap near the bottom. An idea to fix that would be to make the tall shape even taller:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/taller-hex.jpg",
        "alt": "tall hexagon shapes"
      }}></img></p>
    <p>{`But this still had a problem where you wouldn't be able to place any stickers underneath the tall one and have them fit all perfectly together.`}</p>
    <p>{`I played around with about a dozen increasingly crappy ideas before realizing I was frustrated and stuck. I put my mac to sleep, rubbed my eyes, and decided to try something different.`}</p>
    <h2>{`The Experiment`}</h2>
    <p>{`The first thing I did is write down as concisely as possible the problem I was trying to solve. I wrote "make an awesome layout for my laptop stickers, that fans will be proud to show off". Just writing it down seemed to relieve some stress and give me clarity.`}</p>
    <p>{`After that I asked myself "what constraints am I operating under right now?". I wrote them down:`}</p>
    <ul>
      <li parentName="ul">{`devs love laptop stickers`}</li>
      <li parentName="ul">{`hexagons are the ideal shape`}</li>
      <li parentName="ul">{`main characters should take up larger hexagons`}</li>
      <li parentName="ul">{`hexagons are pointy side up`}</li>
      <li parentName="ul">{`shapes should fit together with no gaps`}</li>
    </ul>
    <p>{`I was going to keep working at it, but we had a family outing planned that I didn't want to miss. So I closed my laptop and took my wife and kids to enjoy the fall festivities at Cornbelly's. While launching my kids on the zip lines and devouring terrible fair food I didn't think about my layout problem once. But when we passed by this shoe cubby I couldn't help but stop and stare:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/shoe-cubby.jpg",
        "alt": "shoe cubby"
      }}></img></p>
    <p>{`And just like that, something clicked. My mind drew lines around these hexagon shapes in a way that would be absolutely perfect for my layout:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/hex-shoe-cubby.gif",
        "alt": "inspiration strikes"
      }}></img></p>
    <p>{`Because I had clarified what I was trying to solve, I had defined `}<strong parentName="p">{`something tangible`}</strong>{` for my brain to chew on in the background. Because I had `}<strong parentName="p">{`questioned my constraints`}</strong>{`, I was free to discard many of them. I didn't `}<em parentName="p">{`have`}</em>{` to lengthen a hexagon to make a bigger sticker: I could just use two vertically. Or three in an L-shape. And they'll always fit together perfectly. I didn't have to use pointy tips up, that's just the way I'm used to seeing them on most people's laptops. Thanks to this strike of inspiration I'm no longer stuck on the project and am excited to show you the final result when they're done.`}</p>
    <h2>{`The Formula`}</h2>
    <p>{`I didn't realize it at the time, but this is the same way I got unstuck the time I had a crazy webgl perf issue `}<a parentName="p" {...{
        "href": "/post/easy-on-the-webgl/"
      }}>{`when I first shipped`}</a>{` Flexbox Zombies. Having learned from these and other experiences I'm now able to extract a formula:`}</p>
    <div className="formula">
  1. Write down the precise problem to be solved
  <br />
  2. List and question all the constraints you're operating under (both real and
  imagined)
  <br />
  3. Walk away, rejuvenate, process the problem in the background
    </div>
    <p>{`If you do this there's a good chance you'll randomly think of something when you least expect it. It also helps to talk through the problem with a friend, get their take on things. Just remember that inspiration takes time to strike and you can't rush it. But if in a day or two if you still haven't solved it, come back to the problem fresh and try again. It's important to `}<strong parentName="p">{`keep experimenting`}</strong>{` - that's what gives your mind more raw input materials to work with. You may hit another wall or three and that's totally fine. Just repeat these steps as many times as it takes. Don't give up, you'll figure it out.`}</p>
    <h2>{`What Are You Stuck On?`}</h2>
    <p>{`If you're like me and Paul Simon - feeling like inspiration just isn't coming your way - try out these steps. You'll soon get unstuck and be back to making sweet progress.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      